import { useQuery } from '@tanstack/react-query';

import { getCpmsConnector } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';

export const useConnectorData = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.CONNECTOR_ITEM, id],
    queryFn: () => getCpmsConnector({ path: { id } }).then(({ data }) => data),
  });
};
