import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GeolocationIconSvg from '@/assets/images/geolocation.svg';
import { Button } from '@/shadcn/ui/button.tsx';

export const ChargeSitesEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full my-16 gap-6">
      <img src={GeolocationIconSvg} alt="GeolocationIcon Icon" />

      <Link to="/locations/add">
        <Button variant="secondary">{t('CHARGE_SITES_PAGE.ADD_CHARGE_SITE')}</Button>
      </Link>
    </div>
  );
};
