import { useQuery } from '@tanstack/react-query';

import { getCpmsChargePoint } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';

export const useChargePointData = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_POINT_ITEM, id],
    queryFn: () => getCpmsChargePoint({ path: { id } }).then(({ data }) => data),
  });
};
