import GearIcon from '@/assets/icons/gear.svg?react';
import { Icon } from '@/components/icon';
import { Button } from '@/shadcn/ui/button';

type Props = {
  title: string;
};

export const DisabledSettingsButton = ({ title }: Props) => (
  <Button variant="text" disabled={true}>
    {title}
    <Icon reactIcon={<GearIcon />} />
  </Button>
);
