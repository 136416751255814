import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{ header?: string }>;

export const EditFormLayout = ({ header, children }: Props) => {
  return (
    <>
      <div className="text-xs h-40 text-muted flex justify-center items-center">{header}</div>
      <div className="h-full [&>form]:relative [&>form]:h-full [&>form]:flex [&>form]:flex-col [&>form]:justify-between [&>form]:bg-background-muted [&>form]:-mx-6 [&>form]:p-6 [&>form]:grow">
        {children}
      </div>
    </>
  );
};
