import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';

import { t } from '@/i18n/i18n';
import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shadcn/ui/accordion';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

import { ChargePointsTable } from './charge-points-table';

type ChargeSite = CpmsChargeSiteListResponseDto['items'][number];

const columns: ColumnDef<ChargeSite>[] = [
  {
    accessorKey: 'title',
    header: () => (
      <span className="w-40 pl-6 text-xs">{t('CHARGE_SITE_TABLE_HEADERS.CHARGE_SITE')}</span>
    ),
    cell: (info) => {
      return (
        <div className="p-2 pl-6 w-96 text-left">
          <p className="font-medium text-lg leading-7">
            <Link
              to={`/locations/${info.row.original.id}`}
              className="hover:underline hover:text-primary"
            >
              {info.row.original.title}
            </Link>
          </p>
          <p className="leading-4 text-muted text-xs">{info.row.original.address}</p>
        </div>
      );
    },
  },
  {
    accessorKey: 'chargePointsCount',
    header: () => (
      <span className="text-xs ml-80">{t('CHARGE_SITE_TABLE_HEADERS.CHARGE_POINTS')}</span>
    ),
    cell: (info) => {
      const chargePointsCount = info.row.original.chargePointsCount;

      return <p className="text-lg text-muted p-4 ml-20">{chargePointsCount}</p>;
    },
  },
];

type Props = {
  data: ChargeSite[];
};

export const ChargeSitesTable = ({ data }: Props) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Accordion type="single" collapsible className="h-full pb-6">
      <Table className="rounded-md border-1.5 border-background-muted h-full">
        <TableHeader title={t('CHARGE_SITES_PAGE.TABLE.TITLE')}>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              )),
            )}
        </TableHeader>
        <div className="h-[calc(100%-136px)] overflow-y-auto">
          {table.getRowModel().rows.map((row) => (
            <AccordionItem
              value={row.id}
              key={row.id}
              className="relative transition-colors hover:bg-primary-light data-[state=open]:bg-background-muted after:content after:absolute after:bg-background-muted after:mx-5 after:top-14 after:left-0 after:right-0 after:h-[1px] data-[state=open]:after:bg-background"
            >
              <TableRow>
                <AccordionTrigger className="p-0">
                  {row.getVisibleCells().map((cell) => (
                    <Fragment key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Fragment>
                  ))}
                </AccordionTrigger>
              </TableRow>
              <AccordionContent>
                <ChargePointsTable data={row.original.chargePoints} isStandalone={false} />
              </AccordionContent>
            </AccordionItem>
          ))}
        </div>
        <TableRow className="h-10" />
      </Table>
    </Accordion>
  );
};
