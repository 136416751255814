import { useEffect, useState } from 'react';

import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/components/spinner';
import { BASE_BE_URL } from '@/core.const.ts';
import { useToast } from '@/shadcn/hooks/use-toast';
import { CardContent, CardDescription, CardHeader, CardTitle } from '@/shadcn/ui/card';

import { MagicLinkLoginForm } from './magic-link-login-form';

export const EmailLinkLoginPage = () => {
  const { t } = useTranslation();
  const { showErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const redirectTo = new URLSearchParams(location.search).get('redirectTo') ?? '/';

  useEffect(() => {
    const href = window.location.href;
    const email = window.localStorage.getItem('emailForSignIn');

    if (!email) {
      setIsLoading(false);

      return;
    }

    if (email && !isSignInWithEmailLink(getAuth(), href)) {
      console.error('Email or link is not valid');
      setIsLoading(false);

      return;
    }

    signInWithEmailLink(getAuth(), email, href)
      .then(() => {
        navigate(redirectTo);
      })
      .catch((error) => {
        console.error('User not found or link is wrong', error);
        navigate('/login');
      })
      .finally(() => {
        window.localStorage.removeItem('emailForSignIn');
        setIsLoading(false);
      });
  }, [navigate, redirectTo]);

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await sendSignInLinkToEmail(getAuth(), email, {
        url: `${BASE_BE_URL}/auth/verify-email-link?${new URLSearchParams({ email, redirectTo })}`,
        handleCodeInApp: true,
      });
      window.localStorage.setItem('emailForSignIn', email);
      navigate(`/login/sent?${new URLSearchParams({ email })}`);
    } catch {
      showErrorToast(t('EMAIL_LOGIN_PAGE.FAILED_LOGIN_TOAST'));
    }
  };

  if (isLoading) {
    return <Spinner className="h-96" />;
  }

  return (
    <>
      <CardHeader>
        <CardTitle className="text-xl mb-5">{t('EMAIL_LOGIN_PAGE.HEADER')}</CardTitle>
        <CardDescription className="text-sm text-muted">
          {t('EMAIL_LOGIN_PAGE.SUBHEADER')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <MagicLinkLoginForm onSubmit={onSubmit} />
      </CardContent>
    </>
  );
};
