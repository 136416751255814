import { PropsWithChildren, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg?react';
import { Icon } from '@/components/icon';

type Props = PropsWithChildren<{
  title: string;
  hasScroll?: boolean;
  rightSlot?: ReactNode;
  backUrl?: string;
}>;

export const PageLayout = ({ children, rightSlot, title, hasScroll = false, backUrl }: Props) => {
  return (
    <div className={`relative h-screen  ${hasScroll ? 'overflow-y-auto' : 'overflow-hidden'}`}>
      <div className="py-9 pl-9 ml-44 flex items-center justify-between fixed inset-x-6 z-aboveMap bg-background h-28">
        <h1 className="text-3xl font-medium capitalize pl-3 flex items-center">
          {backUrl && (
            <Link to={backUrl} className="inline-block mr-4">
              <Icon reactIcon={<ArrowIcon height="24" width="24" />} className="rotate-180" />
            </Link>
          )}
          {title}
        </h1>
        <div>{rightSlot}</div>
      </div>
      <div className={`px-6 pt-28 w-full ${hasScroll ? 'h-fit' : 'h-screen flex flex-col'}`}>
        {children}
      </div>
    </div>
  );
};
