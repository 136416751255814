import { useQuery } from '@tanstack/react-query';

import { getCpmsTariffs } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';

export const useTariffsData = () => {
  return useQuery({
    queryKey: [QUERY_KEY.TARIFF_LIST],
    queryFn: () => getCpmsTariffs().then(({ data }) => data),
  });
};
