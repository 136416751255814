import { forwardRef, TextareaHTMLAttributes } from 'react';

import { ErrorText } from '@/components/error-text';
import { cc, cn } from '@/lib/utils';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <div>
        <textarea
          className={cn(
            'flex min-h-[60px] w-full rounded-md bg-transparent px-3 py-2 text-base shadow-sm placeholder:text-muted-foreground focus-visible:outline-primary focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            Boolean(error) ? 'border border-destructive' : 'border border-background-muted',
            cc(!error, 'caret-primary'),
            className,
          )}
          ref={ref}
          {...props}
        />
        <ErrorText>{error}</ErrorText>
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export { Textarea };
