import { forwardRef, InputHTMLAttributes } from 'react';

import { ErrorText } from '@/components/error-text';
import { cc, cn } from '@/lib/utils';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <div>
        <input
          type={type}
          className={cn(
            'flex h-14 w-full rounded-sm bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-primary focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            Boolean(error) ? 'border border-destructive' : 'border border-background-muted',
            cc(!error, 'caret-primary'),
            className,
          )}
          ref={ref}
          {...props}
        />
        <ErrorText>{error}</ErrorText>
      </div>
    );
  },
);

Input.displayName = 'Input';

export { Input };
