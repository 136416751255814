import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '@/common/root/page-layout';
import { CreateChargeSiteData } from '@/open-api/_api';
import { ChargeSiteForm } from '@/pages/charge-site/components/charge-site-form';

import { useCreateChargeSiteMutation } from './hooks/create-charge-site.mutation';

export const AddChargeSitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: createChargeSite } = useCreateChargeSiteMutation();

  const handleSubmit = async (body: CreateChargeSiteData['body']) => {
    await createChargeSite(body);
    navigate('/locations');
  };

  return (
    <PageLayout title={t('ADD_CHARGE_SITE_PAGE.PAGE_NAME')}>
      <ChargeSiteForm onSubmit={handleSubmit} />
    </PageLayout>
  );
};
