import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getDefaultConnector, isDefined } from '@/lib/utils';

import { FormConnectorFields } from './connector-edit-form';

type Props = {
  chargePointReadableId: string | undefined;
  chargePointIndex?: number;
};

export const ChargePointEditForm = ({ chargePointReadableId, chargePointIndex }: Props) => {
  const { t } = useTranslation();
  const { fields, append } = useFieldArray({
    name: isDefined(chargePointIndex)
      ? `chargePoints.${chargePointIndex}.connectors`
      : 'connectors',
    rules: { minLength: 1 },
  });

  const handleAddDefaultConnector = () => append(getDefaultConnector());

  return (
    <div className="pb-4 border-muted border-1.5 rounded-md">
      <div className="font-bold text-lg px-12 pt-8 pb-6 flex justify-between items-center">
        {t('CHARGE_POINT_FORM.CHARGE_POINT')} #{chargePointReadableId}
      </div>
      {fields.map((field, connectorIndex) => (
        <FormConnectorFields
          key={field.id}
          connectorIndex={connectorIndex}
          chargePointIndex={chargePointIndex}
          connectorReadableId={`${chargePointReadableId}.${connectorIndex + 1}`}
          onAdd={connectorIndex === fields.length - 1 ? handleAddDefaultConnector : undefined}
        />
      ))}
    </div>
  );
};
