import { useNavigate } from 'react-router-dom';

import { t } from '@/i18n/i18n';
import { Button } from '@/shadcn/ui/button';

import Island404Svg from '../../assets/images/404.svg';
import CatSvg from '../../assets/images/cat.svg';
import IonLogo from '../../assets/logos/ion.svg';

interface Props {
  icon?: '404' | 'cat';
  title?: string;
  description?: string;
  resetError?: () => void;
}

export const ErrorLayout = ({
  icon = '404',
  title = t('ERRORS.404.TITLE'),
  description = t('ERRORS.404.DESCRIPTION'),
  resetError = () => {},
}: Props) => {
  const navigate = useNavigate();

  const handleResetError = () => {
    resetError();

    if (window.history.state.idx === 0) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <img
        src={IonLogo}
        alt="ION logo"
        width={32}
        height={32}
        className="absolute bottom-4 left-4"
      />
      <div className="h-screen flex justify-center items-center flex-col">
        <img src={icon === '404' ? Island404Svg : CatSvg} alt="error image" />
        <span className="text-3xl font-poppins text-center my-8 font-light w-2/5">
          <span className="font-medium">{title}: </span>
          {description}
        </span>
        <Button onClick={handleResetError} className="m-4 min-w-48">
          {t('GO_BACK')}
        </Button>
      </div>
    </>
  );
};
