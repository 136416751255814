import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';

import Archive2Icon from '@/assets/icons/archive2.svg?react';
import { CONNECTOR_LABEL_MAP, CONNECTOR_TYPES } from '@/core.const';
import { isDefined } from '@/lib/utils';
import {
  ArrayEditChargePointFormType,
  EditChargePointFormType,
} from '@/pages/charge-point/edit-charge-point.page';
import { useTariffsData } from '@/pages/charge-point/hooks/use-tariffs.query';
import { Button } from '@/shadcn/ui/button';
import { Input } from '@/shadcn/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shadcn/ui/select';

import { ErrorText } from './error-text';
import { Icon } from './icon';

type Props = {
  connectorIndex: number;
  chargePointIndex?: number;
  connectorReadableId: string | undefined;
  onDelete?: (id: number) => void;
  onAdd?: () => void;
};

export const FormConnectorFields = ({
  connectorIndex,
  chargePointIndex,
  connectorReadableId,
  onDelete,
  onAdd,
}: Props) => {
  const { data: tariffs } = useTariffsData();
  const { watch, setValue, register, formState } = useFormContext<
    EditChargePointFormType | ArrayEditChargePointFormType
  >();

  const baseName = isDefined(chargePointIndex)
    ? (`chargePoints.${chargePointIndex}.` as const)
    : '';
  const typeName = `${baseName}connectors.${connectorIndex}.type` as const;
  const tariffIdName = `${baseName}connectors.${connectorIndex}.tariffId` as const;
  const powerWtName = `${baseName}connectors.${connectorIndex}.powerWt` as const;

  return (
    <div>
      <div className="flex justify-between gap-4 items-between px-8">
        <Input className="w-28" disabled value={connectorReadableId ?? ''} />
        <Select
          defaultValue={watch(typeName).toString()}
          onValueChange={(value) =>
            setValue(
              typeName,
              Number(value) as (typeof CONNECTOR_TYPES)[keyof typeof CONNECTOR_TYPES],
            )
          }
        >
          <SelectTrigger className="bg-background min-w-36 grow-0">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(CONNECTOR_LABEL_MAP).map(([key, value]) => (
              <SelectItem key={key} value={key}>
                {value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="relative w-36 grow-0">
          <Input
            className="relative"
            type="number"
            {...register(powerWtName, { valueAsNumber: true })}
          />
          <span className="absolute top-4 right-3 pr-1 text-muted bg-background">kW</span>
        </div>
        <Select
          defaultValue={watch(tariffIdName).toString()}
          onValueChange={(value) => setValue(tariffIdName, Number(value))}
        >
          <SelectTrigger className="bg-background">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {tariffs?.map((tariff) => (
              <SelectItem key={tariff.id} value={tariff.id.toString()}>
                {tariff.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {connectorIndex !== 0 && onDelete && (
          <Button
            type="button"
            variant="outline"
            className="h-14 w-14 border-primary bg-transparent text-primary"
            onClick={() => onDelete(connectorIndex)}
          >
            <Icon reactIcon={<Archive2Icon />} className="scale-75" />
          </Button>
        )}
        {onAdd && (
          <Button type="button" className="h-14 w-14" onClick={onAdd}>
            +
          </Button>
        )}
      </div>
      <ErrorMessage
        errors={formState.errors}
        name={powerWtName}
        render={({ message }) => (
          <div className="ml-72">
            <ErrorText>{message}</ErrorText>
          </div>
        )}
      />
    </div>
  );
};
