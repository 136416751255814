import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import ArchiveIcon from '@/assets/icons/archive.svg?react';
import EditIcon from '@/assets/icons/edit.svg?react';
import { Icon } from '@/components/icon';
import { Modal } from '@/components/modal';
import { SettingsMenu } from '@/components/settings-menu';
import { CpmsChargePointResponseDto } from '@/open-api/_api';
import { Button } from '@/shadcn/ui/button';
import { DropdownMenuItem } from '@/shadcn/ui/dropdown-menu';

import { useDeleteChargePointMutation } from '../hooks/delete-charge-point.mutation';

type Props = {
  chargePoint: CpmsChargePointResponseDto;
};

export const ChargePointSettingsMenu = ({ chargePoint }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: deleteChargePoint, isPending } = useDeleteChargePointMutation();

  const handleDelete = async () => {
    await deleteChargePoint({
      chargeSiteId: chargePoint.chargeSite.id,
      chargePointId: chargePoint.id,
    });
    navigate(`/locations/${chargePoint.chargeSite.id}`);
  };

  return (
    <SettingsMenu title="Charger Settings">
      <DropdownMenuItem asChild className="cursor-pointer">
        <Link to={`/chargers/${chargePoint.id}/edit`}>
          <Icon reactIcon={<EditIcon />} className="mr-2" />
          {t('CHARGE_POINT_PAGE.EDIT_CHARGE_POINT')}
        </Link>
      </DropdownMenuItem>
      <Modal
        title={t('CHARGE_POINT_PAGE.ARCHIVE_CHARGE_POINT')}
        subtitle={t('CHARGE_POINT_PAGE.ARCHIVE_MODAL_SUBTITLE', { chargePoint })}
        variant="destructive"
        trigger={
          <DropdownMenuItem className="cursor-pointer" onSelect={(event) => event.preventDefault()}>
            <Icon reactIcon={<ArchiveIcon />} className="mr-2" />
            {t('CHARGE_POINT_PAGE.ARCHIVE_CHARGE_POINT')}
          </DropdownMenuItem>
        }
        description={<div>{t('CHARGE_POINT_PAGE.ARCHIVE_MODAL_DESCRIPTION', { chargePoint })}</div>}
        renderConfirmButton={(close) => (
          <Button
            className="min-w-48"
            isLoading={isPending}
            onClick={async () => {
              await handleDelete();
              close();
            }}
          >
            {t('ARCHIVE')}
          </Button>
        )}
      />
    </SettingsMenu>
  );
};
