import { useTranslation } from 'react-i18next';

import { PageLayout } from '@/common/root/page-layout';
import { DisabledSettingsButton } from '@/components/disabled-settings-button';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const ChargeSitePageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t('SKELETON.LOADING')}
      rightSlot={<DisabledSettingsButton title={t('SKELETON.CHARGE_SITE_SETTINGS')} />}
    >
      <>
        <div className="flex">
          <div className="w-1/2 flex-col gap-4">
            <div className="flex gap-4">
              <Skeleton height="widget" />
              <Skeleton height="widget" gradientOrigin="right" />
            </div>
            <div className="flex my-4">
              <Skeleton height="widget" gradientOrigin="center" />
            </div>
            <div className="flex gap-4">
              <Skeleton height="widget" />
              <Skeleton height="widget" gradientOrigin="right" />
            </div>
          </div>
          <div className="w-1/2 ml-4 z-0">
            <div className="h-full mb-4 ">
              <Skeleton gradientOrigin="center" />
            </div>
          </div>
        </div>
        <div className="my-6">
          <Skeleton height="tableRow" />
          <Skeleton height="tableRow" gradientOrigin="center" className="mt-2" />
          <Skeleton height="tableRow" gradientOrigin="right" className="mt-2" />
        </div>
      </>
    </PageLayout>
  );
};
