import { zodResolver } from '@hookform/resolvers/zod';
import { LeafletEventHandlerFnMap } from 'leaflet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { EditFormLayout } from '@/common/root/edit-form-layout';
import { ErrorText } from '@/components/error-text';
import { LocationMarker } from '@/components/leaflet/location-marker';
import { MapComponent } from '@/components/leaflet/map';
import { t } from '@/i18n/i18n';
import { isDefined } from '@/lib/utils';
import { CreateChargeSiteData, UpdateChargeSiteData } from '@/open-api/_api';
import { Button } from '@/shadcn/ui/button';
import { Input } from '@/shadcn/ui/input';
import { Textarea } from '@/shadcn/ui/textarea';

const chargeSiteFormSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number({ message: t('CHARGE_SITE_FORM.LOCATION_ERROR_MESSAGE') }),
  longitude: z.number({ message: t('CHARGE_SITE_FORM.LOCATION_ERROR_MESSAGE') }),
});

type ChargeSiteFormSchemaType = z.infer<typeof chargeSiteFormSchema>;

type Props = {
  isLoading?: boolean;
  defaultValues?: UpdateChargeSiteData['body'] | CreateChargeSiteData['body'];
  chargeSiteId?: number;
  onSubmit: SubmitHandler<ChargeSiteFormSchemaType>;
};

export const ChargeSiteForm = ({ isLoading, defaultValues, onSubmit, chargeSiteId }: Props) => {
  const { register, handleSubmit, formState, watch, setValue } = useForm<ChargeSiteFormSchemaType>({
    resolver: zodResolver(chargeSiteFormSchema),
    values: defaultValues,
  });

  const handleMapClick: LeafletEventHandlerFnMap['click'] = ({ latlng }) => {
    setValue('latitude', latlng.lat, { shouldValidate: true });
    setValue('longitude', latlng.lng, { shouldValidate: true });
  };

  const position = {
    lat: watch('latitude'),
    lng: watch('longitude'),
  };

  const isLocationMarkerVisible = isDefined(position.lat) && isDefined(position.lng);
  const cancelUrl = `/locations${chargeSiteId ? '/' : ''}${chargeSiteId ?? ''}`;

  return (
    <EditFormLayout header={t('CHARGE_SITE_FORM.HEADER')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-6 grow">
          <div className="w-1/2 flex flex-wrap gap-6 h-full">
            <div className="flex flex-col flex-1 gap-8 h-full">
              <Input
                {...register('title')}
                placeholder={t('CHARGE_SITE_FORM.CHARGE_SITE_TITLE')}
                error={formState.errors.title?.message}
                disabled={isLoading}
              />
              <Textarea
                {...register('description')}
                placeholder={t('CHARGE_SITE_FORM.DESCRIPTION')}
                className="h-24 bg-background"
                error={formState.errors.description?.message}
                disabled={isLoading}
              />
              <div className="relative">
                {/* <span className="absolute inset-y-0 left-3 flex items-center text-muted">
                    <img src={SearchIcon} alt="Search icon" />
                  </span> */}
                <Input
                  {...register('address')}
                  placeholder={t('CHARGE_SITE_FORM.ADDRESS')}
                  className="pl-12"
                  error={formState.errors.address?.message}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <MapComponent onMapClick={handleMapClick} closePopupOnClick={false}>
              {isLocationMarkerVisible && (
                <LocationMarker
                  position={position}
                  eventHandlers={{ click: (event) => event.target.closePopup() }}
                />
              )}
            </MapComponent>
            <ErrorText>
              {formState.errors.latitude?.message ?? formState.errors.longitude?.message}
            </ErrorText>
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <Link to={cancelUrl} className="mr-4">
            <Button type="button" variant="outline">
              {t('CANCEL')}
            </Button>
          </Link>
          <Button
            type="submit"
            className="min-w-48"
            isLoading={formState.isSubmitting}
            disabled={isLoading}
          >
            {t('NEXT')}
          </Button>
        </div>
      </form>
    </EditFormLayout>
  );
};
