import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageLayout } from '@/common/root/page-layout';
import { UpdateChargeSiteData } from '@/open-api/_api';
import { ChargeSiteForm } from '@/pages/charge-site/components/charge-site-form';

import { useUpdateChargeSiteMutation } from './hooks/update-charge-site.mutation';
import { useChargeSiteData } from './hooks/use-charge-site-data.query';

export const EditChargeSitePage = () => {
  const { t } = useTranslation();
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));
  const navigate = useNavigate();
  const { mutateAsync: updateChargeSite } = useUpdateChargeSiteMutation();

  const handleSubmit = async (body: UpdateChargeSiteData['body']) => {
    if (!chargeSiteId) {
      return;
    }
    await updateChargeSite({ chargeSiteId: Number(chargeSiteId), body });
    navigate(`/locations/${chargeSiteId}`);
  };

  const defaultValues = useMemo(
    () =>
      chargeSite && {
        ...chargeSite,
        address: chargeSite.address ?? undefined,
        description: chargeSite.description ?? undefined,
      },
    [chargeSite],
  );

  return (
    <PageLayout title={t('EDIT_CHARGE_SITE_PAGE.PAGE_NAME')}>
      <ChargeSiteForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        chargeSiteId={chargeSite?.id}
      />
    </PageLayout>
  );
};
