import { useQuery } from '@tanstack/react-query';

import { MAP_SELECT_DISTANCE_KM } from '@/core.const';
import { getCpmsFindChargeSiteByArea } from '@/open-api/_api';

import { QUERY_KEY } from '../../../tanstack/query-keys.const';

export const useChargeSitesWithinArea = (latitude: number, longitude: number) => {
  const refetchInterval = 60_000;

  return useQuery({
    queryKey: [QUERY_KEY.CHARGE_SITE_LIST_BY_AREA],
    queryFn: () =>
      getCpmsFindChargeSiteByArea({
        query: {
          distance: MAP_SELECT_DISTANCE_KM.toString(),
          latitude: latitude.toString(),
          longitude: longitude.toString(),
        },
      }).then(({ data }) => data),
    refetchInterval,
  });
};
