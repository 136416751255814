import { use as i18nextUse } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translations/en.json';

export const i18nResources = {
  en: {
    translation: translationEN,
  },
} as const;

export const t = await i18nextUse(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    lng: 'en',
    compatibilityJSON: 'v4',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
