import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Spinner } from '@/components/spinner';
import { CpmsChargeSiteResponseDto } from '@/open-api/_api';

import { useChargeSiteConnectorsStats } from './hooks/use-charge-site-connectors-stats';

type Props = {
  chargeSite: CpmsChargeSiteResponseDto;
};

export const DashboardMapTooltip = ({ chargeSite }: Props) => {
  const { t } = useTranslation();
  const { data } = useChargeSiteConnectorsStats(chargeSite.id);

  if (!data) {
    return <Spinner className="w-48 h-32" />;
  }

  const labelValueColorMap = [
    [t('DASHBOARD_PAGE.MAP_TOOLTIP.TOTAL_COUNT'), data.connectorsCount, '#6BC7E6'],
    [t('DASHBOARD_PAGE.MAP_TOOLTIP.AVAILABLE_COUNT'), data.availableConnectorsCount, '#30D158'],
    [t('DASHBOARD_PAGE.MAP_TOOLTIP.OCCUPIED_COUNT'), data.occupiedConnectorsCount, '#000000'],
    [t('DASHBOARD_PAGE.MAP_TOOLTIP.UNAVAILABLE_COUNT'), data.unavailableConnectorsCount, '#AEAEB2'],
  ] as const;

  return (
    <>
      <Link to={`/locations/${chargeSite.id}`} className="hover:underline">
        <p className="text-muted ">{chargeSite.title}</p>
      </Link>
      {labelValueColorMap.map(([label, value, color]) => (
        <div className="flex text-foreground justify-between font-normal mt-2" key={label}>
          <span className="mr-2 scale-[2.5]" style={{ color }}>
            •
          </span>
          <span>{label}</span>
          <span className="font-semibold ml-auto">{value}</span>
        </div>
      ))}
    </>
  );
};
