import { useMutation } from '@tanstack/react-query';

import { updateChargePoint } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

import { EditChargePointFormType } from '../edit-charge-point.page';

type Variables = {
  data: EditChargePointFormType['connectors'];
  chargePointId: number;
  chargeSiteId: number;
};

export const useEditChargePointMutation = () => {
  return useMutation({
    mutationFn: ({ data, chargePointId }: Variables) =>
      updateChargePoint({ path: { id: chargePointId }, body: { connectors: data } }).then(
        ({ data }) => data,
      ),

    onSettled: (_, __, { data, chargePointId, chargeSiteId }) => {
      queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CHARGE_POINT_ITEM, chargePointId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        type: 'inactive',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
        type: 'inactive',
      });
      for (const connector of data) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.CONNECTOR_ITEM, connector.id],
          type: 'inactive',
        });
      }
    },
  });
};
