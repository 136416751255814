import { fromWattToKiloWatt } from '@ion-hq/utils';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';

import { ChargePointDto } from '@/common.types.ts';
import { t } from '@/i18n/i18n';
import { Table, TableHeader, TableRow } from '@/shadcn/ui/table';

//eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type ChargeSitesTable } from './charge-sites-table';
import { ConnectorIcon } from './connector-icon';

const columns: ColumnDef<ChargePointDto>[] = [
  {
    accessorKey: 'readableId',
    header: () => (
      <span className="text-xs pl-4 w-20">{t('CHARGE_POINT_TABLE_HEADERS.READABLE_ID')}</span>
    ),
    cell: (info) => (
      <span className="text-lg font-semibold pl-8 w-20">{info.row.original.readableId}</span>
    ),
  },
  {
    accessorKey: 'uniqId',
    header: () => (
      <span className="text-xs pl-3 w-48">{t('CHARGE_POINT_TABLE_HEADERS.UNIQ_ID')}</span>
    ),
    cell: (info) => (
      <span className="font-text-sm pl-4 w-48">
        <Link
          to={`/chargers/${info.row.original.id}`}
          className="hover:text-primary hover:underline"
        >
          {info.row.original.uniqId}{' '}
        </Link>
      </span>
    ),
  },
  {
    accessorKey: 'power',
    header: () => <span className="text-xs w-48">{t('CHARGE_POINT_TABLE_HEADERS.POWER')}</span>,
    cell: (info) => {
      const power =
        info.row.original.maxConnectorPowerWt === info.row.original.minConnectorPowerWt
          ? `${fromWattToKiloWatt(info.row.original.minConnectorPowerWt ?? 0)} kW`
          : `${fromWattToKiloWatt(info.row.original.minConnectorPowerWt ?? 0)} - ${fromWattToKiloWatt(info.row.original.maxConnectorPowerWt ?? 0)} kW`;

      return <span className="font-medium text-lg w-48">{power}</span>;
    },
  },
  {
    accessorKey: 'connectors',
    header: () => (
      <span className="text-xs p-2">{t('CHARGE_POINT_TABLE_HEADERS.CONNECTOR_TYPES')}</span>
    ),
    cell: (info) => (
      <div className="flex gap-2 p-2 text-muted">
        {info.row.original.connectorTypes.map((connectorType, i) => (
          <ConnectorIcon key={`${connectorType}${i}`} connectorType={connectorType} />
        ))}
      </div>
    ),
  },
];

type Props = {
  data: ChargePointDto[];
  /** If true, the table will have a border and a header, otherwise it does not have those so it can be nested inside {@link ChargeSitesTable} */
  isStandalone: boolean;
};

export const ChargePointsTable = ({ data, isStandalone }: Props) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className={isStandalone ? 'rounded-md border-1.5 border-background-muted' : ''}>
      {isStandalone && (
        <TableHeader title={'Chargers'}>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Fragment>
              )),
            )}
        </TableHeader>
      )}
      {table.getRowModel().rows.map((row) => (
        <TableRow
          key={row.id}
          className={`relative transition-colors hover:bg-primary-light after:content after:absolute after:mx-5 after:top-14 after:left-0 after:right-0 after:h-[1px] ${isStandalone ? 'after:bg-background-muted' : 'after:bg-background'}`}
        >
          {row.getVisibleCells().map((cell) => (
            <Fragment key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};
