import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { t } from '@/i18n/i18n';
import { Button } from '@/shadcn/ui/button';
import { Input } from '@/shadcn/ui/input';

const magicLinkLoginFormSchema = z.object({
  email: z.string().email({ message: t('EMAIL_LOGIN_PAGE.EMAIL_ERROR_MESSAGE') }),
});

const useFormOptions = {
  resolver: zodResolver(magicLinkLoginFormSchema),
  defaultValues: {
    email: '',
  },
};

type MagicLinkLoginFormSchemaType = z.infer<typeof magicLinkLoginFormSchema>;

type Props = {
  onSubmit: SubmitHandler<MagicLinkLoginFormSchemaType>;
};

export const MagicLinkLoginForm = ({ onSubmit }: Props) => {
  const { register, formState, handleSubmit } =
    useForm<MagicLinkLoginFormSchemaType>(useFormOptions);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-16">
      <Input
        {...register('email')}
        placeholder={t('EMAIL_LOGIN_FORM.INPUT_PLACEHOLDER')}
        className="mt-10"
        error={formState.errors.email?.message}
      />
      <p className="text-xs my-10 text-muted">{t('EMAIL_LOGIN_FORM.DISCLAIMER')}</p>
      <Button type="submit" size="fullWidth" isLoading={formState.isSubmitting}>
        {t('EMAIL_LOGIN_FORM.SUBMIT_BUTTON')}
      </Button>
    </form>
  );
};
