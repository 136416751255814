import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { PageLayout } from '@/common/root/page-layout';
import { LocationMarker } from '@/components/leaflet/location-marker';
import { MapComponent } from '@/components/leaflet/map';
import { Widget } from '@/components/widget';
import { FAST_CHARGE_TRESHOLD, MAP_START_POSITION } from '@/core.const';
import { Skeleton } from '@/shadcn/ui/skeleton';
import { QUERY_KEY } from '@/tanstack/query-keys.const';

import { DashboardMapTooltip } from './dashboard-map-tooltip';
import { cpmsChargeSiteConnectorsStatsQueryFn } from './hooks/use-charge-site-connectors-stats';
import { useChargeSitesWithinArea } from './hooks/use-charge-sites-area.query';

const getMapStartPosition = (): [number, number] => {
  const savedMapPositionRaw = localStorage.getItem('mapStartPosition');

  return savedMapPositionRaw
    ? JSON.parse(savedMapPositionRaw)
    : [MAP_START_POSITION.lat, MAP_START_POSITION.lng];
};

export const DashboardPage = () => {
  const { t } = useTranslation();
  const { data } = useChargeSitesWithinArea(...getMapStartPosition());
  const queryClient = useQueryClient();
  const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);

  return (
    <PageLayout title={t('DASHBOARD_PAGE.PAGE_NAME')} hasScroll>
      <div className="h-96 mb-4">
        {data ? (
          <MapComponent onMapClick={() => setSelectedSiteId(null)}>
            {data.map((chargeSite) => {
              const isLocationActive = chargeSite.chargePoints.some((chargePoint) =>
                chargePoint.connectors.some((connector) => connector.state.isActive),
              );
              const isFastCharge = chargeSite.chargePoints.some((chargePoint) =>
                chargePoint.connectors.some(
                  (connector) => connector.powerWt >= FAST_CHARGE_TRESHOLD,
                ),
              );

              return (
                <LocationMarker
                  position={{ lat: chargeSite.latitude, lng: chargeSite.longitude }}
                  key={chargeSite.id}
                  isSelected={selectedSiteId === chargeSite.id}
                  isActive={isLocationActive}
                  isFastCharge={isFastCharge}
                  eventHandlers={{
                    click: () => setSelectedSiteId(chargeSite.id),
                    mouseover: () => {
                      queryClient.prefetchQuery({
                        queryKey: [QUERY_KEY.CHARGE_SITE_CONNECTOR_STATS, chargeSite.id],
                        queryFn: cpmsChargeSiteConnectorsStatsQueryFn,
                      });
                    },
                  }}
                  tooltipContent={selectedSiteId && <DashboardMapTooltip chargeSite={chargeSite} />}
                />
              );
            })}
          </MapComponent>
        ) : (
          <Skeleton />
        )}
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col gap-4 mb-4 w-full">
          <div className="flex gap-4">
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.SUCCESSFUL_SESSIONS')}
              content={'0'}
              linkTo="#"
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.AVERAGE_SUCCESS_RATE')}
              content={'0%'}
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.AVERAGE_UTILIZATION_RATE')}
              content={'0%'}
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title={t('DASHBOARD_PAGE.NETWORK_UPTIME')}
              content={'0%'}
              contentProps={{ size: '5xl' }}
            />
          </div>
          <div className="flex gap-4">
            <Widget
              title={t('DASHBOARD_PAGE.TOTAL_REVENUE_GENERATED')}
              fullWidth
              doubleHeightContent
              contentProps={{ size: '5xl' }}
            />
            <Widget
              title={t('DASHBOARD_PAGE.AVERAGE_SESSION_DURATION')}
              fullWidth
              doubleHeightContent
              contentProps={{ size: '5xl' }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 w-1/4">
          <Widget
            title={t('DASHBOARD_PAGE.FAILED_CHARGING_SESSIONS')}
            fullWidth
            content={'0'}
            contentProps={{ size: '5xl' }}
            doubleHeightContent
          />
          <Widget
            title={t('DASHBOARD_PAGE.MAINTENANCE_TASKS')}
            fullWidth
            content={'0'}
            contentProps={{ size: '5xl' }}
          />
        </div>
      </div>
    </PageLayout>
  );
};
