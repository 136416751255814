import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ChargerIcon from '@/assets/icons/charger.svg?react';
import DescriptionIcon from '@/assets/icons/description.svg?react';
import GeolocationIcon from '@/assets/icons/geolocation.svg?react';
import { PageLayout } from '@/common/root/page-layout.tsx';
import { LocationMarker } from '@/components/leaflet/location-marker.tsx';
import { MapComponent } from '@/components/leaflet/map.tsx';
import { Widget } from '@/components/widget.tsx';
import { isDefined } from '@/lib/utils';
import { ChargeSiteChargePointList } from '@/pages/charge-site/components/charge-site-charge-point-list';
import { useChargeSiteData } from '@/pages/charge-site/hooks/use-charge-site-data.query';

import { getChargeSiteLatLng } from '../utils/charge-site.utils';
import { ChargeSitePageSkeleton } from './charge-site.page-skelleton';
import { ChargeSiteSettingsMenu } from './charge-site-settings-menu';

export const ChargeSitePage = () => {
  const { t } = useTranslation();
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));

  if (isLoading || !isDefined(chargeSite)) {
    return <ChargeSitePageSkeleton />;
  }

  const chargeSiteCoordinates = getChargeSiteLatLng(chargeSite);

  return (
    <PageLayout
      title={chargeSite.title}
      hasScroll
      rightSlot={
        <ChargeSiteSettingsMenu chargeSiteId={chargeSite.id} chargeSiteTitle={chargeSite.title} />
      }
      backUrl="/locations"
    >
      <div className="flex">
        <div className="w-1/2 flex-col gap-4">
          <div className="flex gap-4">
            <Widget
              fullWidth
              title={t('CHARGE_SITE_PAGE.TITLE')}
              content={<div className="min-h-12 flex items-end"> {chargeSite.title}</div>}
              reactIcon={<ChargerIcon />}
            />
            <Widget
              fullWidth
              title={t('COORDINATES')}
              reactIcon={<GeolocationIcon />}
              content={
                <span>
                  {chargeSite.latitude}
                  <br />
                  {chargeSite.longitude}
                </span>
              }
            />
          </div>
          <div className="flex my-4">
            <Widget
              fullWidth
              reactIcon={<DescriptionIcon />}
              title={t('CHARGE_SITE_PAGE.DESCRIPTION')}
              content={chargeSite.description}
              contentProps={{ text: 'text-muted' }}
            />
          </div>
          <div className="flex gap-4">
            <Widget
              title={t('CHARGE_SITE_PAGE.CHARGE_POINTS_COUNT')}
              content={chargeSite.chargePoints.length.toString()}
              reactIcon={<ChargerIcon />}
              contentProps={{ size: '5xl' }}
            />
            <Widget
              fullWidth
              title={t('CHARGE_SITE_PAGE.AVERAGE_UTILIZATION_RATE')}
              content="100%"
              contentProps={{ size: '5xl' }}
            />
          </div>
        </div>
        <div className="w-1/2 ml-4 z-0">
          <div className="h-full mb-4 ">
            <MapComponent center={chargeSiteCoordinates} closePopupOnClick={false}>
              <LocationMarker
                position={chargeSiteCoordinates}
                isSelected
                tooltipContent={<p>{chargeSite.title}</p>}
              />
            </MapComponent>
          </div>
        </div>
      </div>
      <div className="my-6">
        <ChargeSiteChargePointList data={chargeSite.chargePoints} />
      </div>
    </PageLayout>
  );
};
