import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { bootstrapFirebase } from '@/firebase.setup.ts';

import { App } from './app';
import { bootstrapOpenApiClient } from './open-api/open-api-client.setup';
import { bootstrapSentry } from './sentry.setup';

import './i18n/i18n';
import './index.css';

bootstrapSentry();
bootstrapFirebase();
bootstrapOpenApiClient();

createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
