import ConnectorCCSIcon from '@/assets/icons/connectors/ccs.svg?react';
import ConnectorType1Icon from '@/assets/icons/connectors/type-1.svg?react';
import ConnectorType2Icon from '@/assets/icons/connectors/type-2.svg?react';
import { CONNECTOR_TYPES } from '@/core.const';

import { Icon } from './icon';

const connectorTypeIconsMap: Record<number, any> = {
  [CONNECTOR_TYPES.CCS]: ConnectorCCSIcon,
  [CONNECTOR_TYPES.TYPE1]: ConnectorType1Icon,
  [CONNECTOR_TYPES.TYPE2]: ConnectorType2Icon,
};

type Props = {
  connectorType: keyof typeof connectorTypeIconsMap;
} & React.SVGProps<SVGSVGElement>;

export const ConnectorIcon = ({ connectorType, ...iconProps }: Props) => {
  const Connector = connectorTypeIconsMap[connectorType];

  return <Icon reactIcon={<Connector {...iconProps} />} />;
};
