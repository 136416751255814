import { zodResolver } from '@hookform/resolvers/zod';
import { fromKiloWattToWatt, fromWattToKiloWatt } from '@ion-hq/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { EditFormLayout } from '@/common/root/edit-form-layout';
import { PageLayout } from '@/common/root/page-layout';
import { ChargePointEditForm } from '@/components/charge-point-edit-form';
import { Spinner } from '@/components/spinner';
import { CONNECTOR_TYPES } from '@/core.const';
import { isDefined } from '@/lib/utils';
import { Button } from '@/shadcn/ui/button';

import { useEditChargePointMutation } from './hooks/edit-charge-point-mutation';
import { useChargePointData } from './hooks/use-charge-point.query';
import { useTariffsData } from './hooks/use-tariffs.query';

const editChargePointFormSchema = z.object({
  id: z.number(),
  connectors: z.array(
    z.object({
      id: z.number().positive().int().nullable(),
      powerWt: z.number().positive().int(),
      type: z.nativeEnum(CONNECTOR_TYPES),
      tariffId: z.number(),
    }),
  ),
});

const arrayOfEditChargePointFormSchema = z.object({
  chargeSiteId: z.number(),
  chargePoints: z.array(editChargePointFormSchema),
});

export type ArrayEditChargePointFormType = z.infer<typeof arrayOfEditChargePointFormSchema>;
export type EditChargePointFormType = z.infer<typeof editChargePointFormSchema>;

export const EditChargePointPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chargePointId } = useParams();
  const { isLoading: isLoadingTariffs } = useTariffsData();
  const { data: chargePoint, isLoading: isLoadingChargePoint } = useChargePointData(
    Number(chargePointId),
  );
  const { mutateAsync, isPending } = useEditChargePointMutation();

  const formHandlers = useForm<EditChargePointFormType>({
    values: {
      id: chargePoint?.id ?? 0,
      connectors: (chargePoint?.connectors ?? []).map((connector) => ({
        id: connector.id,
        powerWt: Number(fromWattToKiloWatt(connector.powerWt)),
        type: connector.type,
        tariffId: connector.tariff.id,
      })),
    },

    resolver: zodResolver(editChargePointFormSchema),
  });

  if (isLoadingChargePoint || isLoadingTariffs || !isDefined(chargePoint)) {
    return <Spinner className="h-full" />;
  }

  const handleSubmit = formHandlers.handleSubmit(async (data) => {
    await mutateAsync({
      data: data.connectors.map((connector) => ({
        ...connector,
        powerWt: fromKiloWattToWatt(connector.powerWt),
      })),
      chargePointId: chargePoint.id,
      chargeSiteId: chargePoint.chargeSite.id,
    });

    navigate(`/chargers/${chargePointId}`);
  });

  return (
    <PageLayout
      title={t('EDIT_CHARGE_POINT_PAGE.PAGE_NAME', { readableId: chargePoint.readableId })}
    >
      <EditFormLayout>
        <FormProvider {...formHandlers}>
          <form onSubmit={handleSubmit}>
            <ChargePointEditForm chargePointReadableId={chargePoint.readableId} />

            <div className="flex justify-between">
              <Link to={`/chargers/${chargePointId}`}>
                <Button variant="outline" type="button">
                  {t('CANCEL')}
                </Button>
              </Link>

              <Button type="submit" isLoading={isPending}>
                {t('SAVE')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </EditFormLayout>
    </PageLayout>
  );
};
