/**
 * Represents the query keys used in the application.
 * NOTE: Please use ENTITY_ITEM, ENTITY_LIST for the standard item/list query keys.
 */
export const QUERY_KEY = {
  CHARGE_SITE_ITEM: 'charge-site-item',
  CHARGE_SITE_LIST: 'charge-site-list',
  CHARGE_SITE_LIST_BY_AREA: 'charge-site-list-by-area',
  CHARGE_SITE_CONNECTOR_STATS: 'charge-site-connectors-stats',
  CHARGE_SITE_STATISTIC: 'charge-site-statistic',

  CHARGE_POINT_ITEM: 'charge-point-item',

  CONNECTOR_ITEM: 'connector-item',

  TARIFF_LIST: 'tariff-list',
};
