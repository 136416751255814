import { useTranslation } from 'react-i18next';

import { PageLayout } from '@/common/root/page-layout';
import { DisabledSettingsButton } from '@/components/disabled-settings-button';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const ConnectorPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t('SKELETON.LOADING')}
      rightSlot={<DisabledSettingsButton title={t('SKELETON.CONNECTOR_SETTINGS')} />}
    >
      <div className="flex mb-4">
        <div className="w-1/2 flex-col gap-4">
          <div className="flex mt-4 gap-4">
            <Skeleton height="widget" />
            <Skeleton height="widget" />
          </div>

          <div className="flex mt-4 gap-4">
            <Skeleton height="widget" gradientOrigin="center" />
            <Skeleton height="widget" gradientOrigin="center" />
          </div>
          <div className="flex mt-4 gap-4">
            <Skeleton height="widget" gradientOrigin="right" />
            <Skeleton height="widget" gradientOrigin="right" />
          </div>
        </div>
        <div className="w-1/2 ml-4 z-0">
          <div className="h-full mb-4 ">
            <Skeleton gradientOrigin="center" />
          </div>
        </div>
      </div>
      <Skeleton height="tableRow" />
    </PageLayout>
  );
};
