import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { is404Error, isForbiddenError, isUnauthorizedError } from '@/lib/utils';

import { ErrorLayout } from './error-layout';

type Props = { error: unknown; resetError: () => void };

export const ErrorFallback = ({ error, resetError }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.error(error);
  }, [error]);

  if (is404Error(error)) {
    return <ErrorLayout resetError={resetError} />;
  }

  if (isUnauthorizedError(error) || isForbiddenError(error)) {
    return (
      <ErrorLayout
        title={t('ERRORS.ACCESS_DENIED.TITLE')}
        description={t('ERRORS.ACCESS_DENIED.DESCRIPTION')}
        resetError={resetError}
      />
    );
  }

  return (
    <ErrorLayout
      title={t('ERRORS.APPLICATION_ERROR.TITLE')}
      description={t('ERRORS.APPLICATION_ERROR.DESCRIPTION')}
      resetError={resetError}
    />
  );
};
